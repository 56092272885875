import React, { Component } from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";
import { HashLink } from "react-router-hash-link";

import logoDefault from "../../assets/images/logo/logo.png";
import logoLight from "../../assets/images/logo/logo-light.png";
import logoDark from "../../assets/images/logo/logo-dark.png";
import logoSymbolDark from "../../assets/images/logo/logo-symbol-dark.png";
import logoSymbolLight from "../../assets/images/logo/logo-symbol-light.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/tapizquentai" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/tapizquent/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/tapizquent/" },
  { Social: <FaXTwitter />, link: "https://x.com/tapizquentai" },
];
class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  closeSidebar() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}

  scrollWithOffset(el) {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  }

  render() {
    console.log("IsMain: ", this.props.isMain);
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color", displayContact } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img src={logoLight} alt="Digital Agency" />;
    } else if (logo === "dark") {
      logoUrl = <img src={logoDark} alt="Digital Agency" />;
    } else if (logo === "symbol-dark") {
      logoUrl = <img src={logoSymbolDark} alt="Digital Agency" />;
    } else if (logo === "symbol-light") {
      logoUrl = <img src={logoSymbolLight} alt="Digital Agency" />;
    } else {
      logoUrl = (
        <img src={logoDefault} width={141} height={50} alt="Digital Agency" />
      );
    }

    return (
      <header className={`header-area header-style-two header--fixed ${color}`}>
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <Link to={this.props.homeLink}>{logoUrl}</Link>
            </div>
            <nav className="mainmenunav d-lg-block ml--50">
              <ul className="mainmenu">
                <li>
                  <HashLink to="/" onClick={this.closeSidebar}>
                    Home
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={this.props.isMain ? "#about" : "/#about"}
                    onClick={this.closeSidebar}
                    scroll={this.scrollWithOffset}
                  >
                    About
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to={this.props.isMain ? "#services" : "/#services"}
                    onClick={this.closeSidebar}
                    smooth={true}
                  >
                    Services
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    to="/#portfolio"
                    onClick={this.closeSidebar}
                    smooth={true}
                  >
                    Portfolio
                  </HashLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header-right">
            <div className="social-share-inner">
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`} target="_blank">
                      {val.Social}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {displayContact && (
              <div className="header-btn">
                <a className="rn-btn" href="/contact">
                  <span>Contact us</span>
                </a>
              </div>
            )}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderThree;
