import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";

const PortfolioList = [
  {
    image: "image-1",
    category: "App Development",
    title: "Capture Pod Photo Booth Experience",
    description:
      "A custom-built photo booth app for a leading Florida company, featuring filters, Dropbox uploads, and options for GIFs, videos, and pictures.",
    url: "https://capturepod.com/",
  },
  {
    image: "image-2",
    category: "AI & Software",
    title: "Hieroglif.ai",
    description:
      "An innovative app for Flutter developers, enabling context-aware translations with a user-friendly dashboard for managing API keys and translations.",
    url: "https://hieroglif.ai/",
  },
  {
    image: "image-3",
    category: "Mobile App",
    title: "YumYumYo: Restaurants and More",
    description:
      "An AI-powered app that suggests restaurants and generates recipes based on user preferences and ingredients, enhancing dining and cooking experiences.",
    url: "https://yumyumyo.app/",
  },
  {
    image: "image-4",
    category: "Lifestyle App",
    title: "ICAY Pets",
    description:
      "A comprehensive pet management app allowing users to track their pets’ activities, vaccinations, and share pet profiles with family and friends.",
    url: "https://apps.apple.com/ng/app/icay-pets/id1508817109",
  },
  {
    image: "image-5",
    category: "Gaming",
    title: "Cubesliding",
    description:
      "An engaging infinite runner game where players navigate a cube through obstacles, collect coins, and experience progressively challenging gameplay.",
    url: "https://apps.apple.com/no/app/cubesliding/id1553719793",
  },
  {
    image: "image-6",
    category: "Web App",
    title: "CreatorMagic AI",
    description:
      "A groundbreaking web app that converts YouTube videos into written content, offering blogs, summaries, and key takeaways using advanced AI technology.",
    url: "https://creatormagicai.com/",
  },
  {
    image: "image-7",
    category: "Mobile App",
    title: "Award Party",
    description:
      "A mobile app that streamlines the process of organizing and hosting award ceremonies, featuring a user-friendly interface and a range of customization options.",
    url: "https://awardparty.app/",
  },
];

class Portfolio extends Component {
  render() {
    let title = "Our Portfolio",
      description =
        "Discover our diverse range of projects, each a testament to our expertise in delivering innovative software solutions that drive success.";

    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2>{title}</h2>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-slick-activation mt--70 mt_sm--40">
            <Slider {...portfolioSlick2}>
              {PortfolioList.map((value, index) => (
                <div className="portfolio" key={index}>
                  <div className="thumbnail-inner">
                    <div className={`thumbnail ${value.image}`}></div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                  </div>
                  <div className="content">
                    <div className="inner">
                      <p>{value.category}</p>
                      <h4 className="title">
                        <a href="/portfolio-details">{value.title}</a>
                      </h4>
                      <div className="portfolio-button">
                        <a className="rn-btn" href={value.url} target="_blank">
                          View Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;
