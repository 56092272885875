import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  render() {
    return (
      <div className="active-dark">
        <React.Fragment>
          <PageHelmet pageTitle="Contact" />

          <HeaderThree
            headertransparent="header--transparent"
            colorblack="color--black"
            logoname="logo.png"
            homeLink="/"
          />

          {/* Start Breadcrump Area */}
          <div className="rn-page-title-area contact_image pt--120 pb--190 bg_image bg_image--17">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient">Contact Us</h2>
                    <p>
                      Ready to bring your ideas to life? Whether you have a
                      question about our services, need assistance, or you're in
                      the planning stages of a big project, our team at
                      TapDevelops is here to help. Reach out to us, and let’s
                      make something amazing together.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Contact Top Area  */}
          <div className="rn-contact-top-area ptb--120 bg_color--5">
            <div className="container">
              <div className="row">
                {/* Start Single Address  */}
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="rn-address">
                    <div className="icon">
                      <FiHeadphones />
                    </div>
                    <div className="inner">
                      <h4 className="title">Give Us A Call</h4>
                      <p>Phone Number: </p>
                      <p>
                        <a href="tel:+1 305 338 7672">+1 305 338 7672</a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                  <div className="rn-address">
                    <div className="icon">
                      <FiMail />
                    </div>
                    <div className="inner">
                      <h4 className="title">Send Us A Message</h4>
                      <p>Email Address:</p>
                      <p>
                        <a href="mailto:info@tapdevelops.com">
                          info@tapdevelops.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}
              </div>
            </div>
          </div>
          {/* End Contact Top Area  */}

          {/* Start Contact Page Area  */}
          <div className="rn-contact-page ptb--120 bg_color--1">
            <ContactTwo />
          </div>
          {/* End Contact Page Area  */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
      </div>
    );
  }
}
export default Contact;
