import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PageScrollTop = (props) => {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  });
  return props.children;
};
export default withRouter(PageScrollTop);
