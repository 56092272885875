import React, { Component, Fragment } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      {
        countNum: 95, // Adjust to the actual number of projects completed
        countTitle: "Projects Delivered",
        description:
          "A diverse range of successful projects, from cutting-edge apps to dynamic websites, reflecting our commitment to innovation and quality.",
      },
      {
        countNum: 50000, // Adjust to the actual number of users
        countTitle: "Users Worldwide",
        description:
          "Empowering a vast user base globally, our applications create engaging, efficient, and impactful digital experiences.",
      },
      {
        countNum: 100, // Adjust to the actual number of satisfied clients
        countTitle: "Satisfied Clients",
        description:
          "Our growing list of happy clients is a testament to our dedication to excellence, client satisfaction, and transformative digital solutions.",
      },
    ];

    return (
      <Fragment>
        <div className="row">
          {Data.map((value, index) => (
            <div
              className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12"
              key={index}
            >
              <h5 className="counter">
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp
                    end={this.state.didViewCountUp ? value.countNum : 0}
                  />
                </VisibilitySensor>
              </h5>
              <p className="description">{value.countTitle}</p>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default CounterOne;
