import React, { Component } from "react";
import { FiMonitor, FiLayers, FiFeather, FiEye } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiMonitor />,
    title: "Website Development",
    description:
      "Crafting responsive and engaging websites tailored to your business needs, ensuring a powerful online presence.",
  },
  {
    icon: <FiLayers />,
    title: "Mobile App Development",
    description:
      "Developing high-performance, intuitive mobile applications for a seamless experience on iOS and Android platforms.",
  },
  {
    icon: <FiFeather />,
    title: "Software Design",
    description:
      "Creating custom software solutions with a focus on functionality, scalability, and user-centric design.",
  },
  {
    icon: <FiEye />,
    title: "UX & UI Design",
    description:
      "Designing captivating user interfaces and experiences that are both aesthetically pleasing and highly functional.",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Services",
      description =
        "Explore our tailored digital solutions in website development, mobile app creation, and custom software design to propel your business forward.";

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">{title}</h2>
              <p>{description}</p>
              <div className="service-btn">
                <a className="btn-transparent rn-btn-dark" href="/contact">
                  <span className="text">
                    Have a Project in Mind? Let's Talk
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
