import React, { Component } from "react";

import aboutImg from "../../../assets/images/about/about-1.png";

class About extends Component {
  render() {
    let title = "About",
      description =
        "At TapDevelops, we believe in the transformative power of technology to elevate businesses and enrich lives. With several years of experience, we have steadily grown into a renowned software design company, driven by a passion for innovation and excellence. Our journey has been marked by a relentless pursuit of creating software solutions that are not just efficient and reliable but also intuitive and user-centric.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src={aboutImg} alt="About Images" />
                </div>
              </div>

              <div className="col-lg-7 col-md-12 pt-lg-5">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          We are TapDevelops - a team of dedicated software
                          developers, creative designers, and strategic
                          visionaries. United by a passion for digital
                          innovation, we excel in turning complex challenges
                          into intuitive and impactful software solutions. Our
                          expertise and collaborative spirit make us more than
                          just a software design company; we are your partners
                          in digital transformation.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">What we do</h3>
                        <p>
                          At TapDevelops, we specialize in three key areas:
                          crafting responsive and engaging websites, developing
                          seamless mobile applications for iOS and Android, and
                          designing custom software solutions that drive
                          efficiency and growth. Our approach is client-centric,
                          ensuring that each project we undertake is not just a
                          task, but a journey towards achieving digital
                          excellence.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
